$(function(){
	//get_social_count();
	//$("div#load_more").click(load_more);
});

function load_more(){
	var ul = $(this).prev("ul");
	var size = ul.children("li").length;
	$.post("/load", {'size':size}, function(data, status) {
		if ( status != "success" ) {
			return;
		}
		$(data).appendTo(ul).hide().fadeIn(500);
		get_social_count();
	});
}

function get_social_count() {
    var list = $(".fb .count").map(function(index, el) { return $(this) });
    if ( !list ) {
    	return;
    }
    for (var i = 0; i < list.length; i++) {
    	var el_f = $(".fb .count:eq("+i+")");
    	var url = el_f.parents("li").children("a:eq(0)").attr("href");
    	get_count_fb(url, el_f);
    }
}

function get_count_fb(url, elm) {
    $.ajax({
        url:'https://graph.facebook.com/',
        dataType:'jsonp',
        data:{ id:url },
        success:function(res){
        	elm.text( res.shares || 0 );
        }, error:function(){
        	elm.text('?');
        }
    });
}

function get_count_tw(url, elm) {
	elm.text('-');
	return;
    $.ajax({
        url:'http://urls.api.twitter.com/1/urls/count.json',
        dataType:'jsonp',
        data:{ url:url },
        success:function(res){
        	elm.text( res.count || 0 );
        }, error:function(){
        	elm.text('?');
        }
    });
}

function side_spy_scroll() {
	var fix = $('.fix');
	var fio = fix.offset();
	var fit = 1850;//fio.top;
	var fih = fix.height();

	var footer = $('#footer');
	var foo = footer.offset();
	var fot = foo.top;

	//var pst = $('.pc_side_top');
	var psh = 600;//pst.height();

	$(window).scroll(function () {
		if ( fot < 2000 ) {
			return;
		}
		var st = $(window).scrollTop();
		if (st > (fot-fih)) {
			fix.removeClass('fixed');
			fix.addClass('fixed2');
		} else if (st > fit) {
			fix.removeClass('fixed2');
			fix.addClass('fixed');
		} else {
			fix.removeClass('fixed');
			fix.removeClass('fixed2');
		}
	});

}

$(function($) {
//	side_spy_scroll();
});
